import React from 'react'
import  { Link } from 'react-router-dom';

import bottle from './bottle.png';
import './bottle.css';

const Bottle = (props) => {
  return (
    <div className="bottle">
      {/* <a href={props.link}> */}
        <img src={bottle} alt="bottle" />
        <div>
          <p>The BOURBON that inspired the journey</p>
          <p className="view story-readmore"><Link className="story-readmore" to={props.link}>Learn More</Link></p>
        </div>
      {/* </a> */}
      </div>
   )
}

export default Bottle;
