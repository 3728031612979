/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import './Footer.css';
import charterOakLogo from '../../images/charteroak.svg';
import facebookLogo from '../../images/facebook.svg';
// import twitterLogo from '../../images/twitter.svg';
import instagramLogo from '../../images/instagram.svg';
const today = new Date();
class Footer extends Component {
  render() {
    return (
      <div className="content">
        <div className="footer">
            <ul className="center">
            <li><a href="mailto:oldcharter@bourbonwhiskey.com">Contact Us</a></li>
            <li><a href="http://www.sazerac.com/SazeracTermsOfUse.html">Terms of Use</a></li>
            <li><a href="http://www.sazerac.com/SazeracAccessibilityPolicy.html">Accessibility Policy</a></li>
            <li><a href="http://www.sazerac.com/SazeracPrivacyPolicy.html">Privacy Policy</a></li>
            <li><a href="http://www.sazerac.com/SazeracModernSlaveryAct.html">Modern Slavery Statement</a></li>
            <li><a href="https://www.buffalotracedistillery.com/">Buffalo Trace Distillery</a></li>
            <li><a href="http://www.sazerac.com/SazeracPrivacyPolicy.html#CAPrivacyNotice" target="_blank" rel="noopener noreferrer">CA Privacy Notice</a></li>
            </ul>
            <div className="center">
                <img className="charter-logo" src={charterOakLogo} alt="charter logo" />
            </div>
            <div className="social center">
            <a href="https://www.facebook.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
                <span className="logo center"><SVG src={facebookLogo} /></span>
            </a>
            <a href="https://www.instagram.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
                <span className="logo center"><SVG src={instagramLogo} /></span>
            </a>
                {/*
            <a href="https://www.twitter.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
                <span className="logo center"><SVG src={twitterLogo} /></span>
            </a>
                */}
            </div>
            <div className="contact" >
            <div className="center">
                <p>&copy; {today.getFullYear()} Buffalo Trace Distillery. All Rights Reserved.&nbsp;</p>
                <p>Please Drink Responsibly.</p>
            </div>
            <div className="center">
                <p>Buffalo Trace Distillery, 113 Great Buffalo Trace,&nbsp;</p>
                <p>Frankfort, KY 40601, United States</p>
                </div>
            <div className="center">
                <a href="tel:1-866-729-3722">1-866-729-3722</a>
                <a href="mailto:oldcharter@bourbonwhiskey.com">oldcharter@bourbonwhiskey.com</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
