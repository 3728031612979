/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React from 'react';

import LazyImage from '../common/LazyImage.jsx';

import './agegate.css';

class AgeGate extends React.Component {
  render() {
    return (
      <div className="gate center">
        <button onClick={this.props.closeAgeGate}>
          <LazyImage
            className='mobile-width-full'
            tinyimg='agegate-transparent_tiny.png'
            fullimg='agegate-new.png'
            alt="agegate" />
        </button>
      </div>
    );
  }
}

export default AgeGate;
