/** 
WWC Utility Library Functions 1.3
*  internalgit/react/lazylibs

Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React, {Component} from 'react';

class LazyBackgroundImage extends Component {
  /* Lazily loads background image, with a blur effect

    Props:
    `fullimg` and `tinyimg` are file paths relative
    to the `src/images/` directory
  */
  constructor(props) {
    super(props);
    const {tinyimg} = props;
    this.state = {
      hdImage: null,
      hdImageLoader: null,
      smImage: null};
    import(`../../images/${tinyimg}`).then(
      img => {
          this.setState({smImage: img.default});
      }
    );
  }

  componentDidMount() {
    const self = this;
    const {fullimg} = self.props;
    const handleLoadingImg = imgPath => {
      const hdImage = new Image();
      hdImage.src = imgPath.default;
      hdImage.onload = () => {
          self.setState({hdImage: hdImage.src});
      };
      this.setState({hdImageLoader: hdImage});
    };
    import (`../../images/${fullimg}`).then(
      handleLoadingImg
    );
  }

  render() {
    const styles = {
      backgroundImage: `url(${this.state.hdImage || this.state.smImage})`,
      transition: 'filter 3s',
      filter: !this.state.hdImage ? 'blur(2px)' : 'none'
    };
    return (
      <div {...this.props}
        style={styles}
      >
        {this.props.children}
        <div style={{display: 'none'}}>
          <img src={this.hdImageLoader}/>
        </div>
      </div>
    );
  }
}

export default LazyBackgroundImage;
