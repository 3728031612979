import React from 'react';
import { withRouter } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import facebookLogo from '../../../images/facebook.svg';
import twitterLogo from '../../../images/twitter.svg';
import envelope from './envelope.png';
import './share.css';

const ShareLinks = (props) => {
  // const url = process.env.REACT_APP_BASE_URL + props.location.pathname;
  const url = window.location.href;
  const text = 'Old Charter Oak';
  const facebookUrl = `https://facebook.com/sharer/sharer.php?u=${encodeURI(url)}`;
  const twitterUrl = `https://twitter.com/intent/tweet/?text=${encodeURI(text)}&url=${encodeURI(url)}`;
  const emailUrl = `mailto:?subject=${encodeURI(text)}&body=${encodeURI(url)}`;
  return (
    <div className="sharing social">
      <div>SHARE</div>
      <a className="center" href={facebookUrl} target="_blank" rel="noopener noreferrer">
        <span className="logo center"><SVG src={facebookLogo} /></span>Facebook
      </a>
      <a className="center" href={twitterUrl} target="_blank" rel="noopener noreferrer">
        <span className="logo center"><SVG src={twitterLogo} /></span>Twitter
      </a>
      <a className="center" href={emailUrl} target="_self">
        <span className="logo center"><img src={envelope} alt="email" /></span>Email
      </a>
    </div>
  )
}

export default withRouter(ShareLinks);
