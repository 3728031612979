import React, { Component } from 'react'

import BgVideo from '../BgVideo';
import WatchVideo from '../../WatchVideo';
import video from '../../../m/stavemill.mp4';

class TheStavemill extends Component {
  render() {
    return (   
      <div className="slide stavemill">
        <BgVideo isVideoActive={this.props.isVideoActive} video={video} />
        <div className="teaser">
          <h1 className="heading">The Stave Mill</h1>
          <h2 className="subheading">Taking Shape</h2>
          <p className="details">
            Every cut matters. White oak logs enter the mill and only the finest leave as rough staves, quarter-cut into the exact specifications for cooperages to create barrels that will hold whiskey for years.
          </p>
          <WatchVideo />
        </div>
      </div>
    )
  }
}

export default TheStavemill;