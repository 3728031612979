import React from 'react'
import  { Link } from 'react-router-dom';

const Chinquapin = (props) => {
  const direction = props.direction === 'right' ? 'ltr' : 'rtl';
  return (
    <div className="story" style={{direction: direction}}>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-lg-1-2 center">
          <img src={props.photo} alt="story" className="pure-img" />
        </div>
        <div className="pure-u-1 pure-u-lg-1-2">
          <div className="heading">{props.heading}</div>
          <p className="detail">How wood, sun, rain, air, fire and time can make a bourbon extraordinary.</p>
          <button className="read-more no-decoration" onClick={() => document.documentElement.scrollTop = 0}><Link to="/story/chinkapin-oak">Read More</Link></button>
        </div>
      </div>
    </div>
  )
}

export default Chinquapin;