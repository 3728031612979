import React from 'react'
import styled from 'styled-components';

import scroll from './scroll.svg';

const ScrollIndicator = styled.div`
  position: fixed;
  width: 15px;
  top: 80vh;
  padding: 25px;
  right: 0;
`;

const Scroll = () => {
  return (
    <ScrollIndicator>
      <img src={scroll} alt="scroll" />
    </ScrollIndicator>
  )
}

export default Scroll;
