import React, { Component } from 'react';

// import speciesOak from '../images/tennesseeoak.png';
// import miniStory from '../images/ministory.png';
import treeWatermark from './centuriestree.svg';

import './centuries.css';

class CenturiesComingSoon extends Component {
  render() {
    const countryBG = {
      backgroundImage: "url('" + treeWatermark + "')",
      //backgroundSize: '40%'
    }
    return (
      <div className="centuries">
        <header className="center">
          <div className="pure-g">
            <div className="bg pure-u-1 pure-u-lg-1-2 center" style={ countryBG }>
                <span className="mt-35">Centuries</span>
                <span className="coming-soon">Coming Soon</span>
            </div>
            <div className="pure-u-1 pure-u-lg-1-2 center-left">
              <div className="text">What difference does a century make? Imagine what a 300 year-old oak has seen in its life. Our Century collection will tell those stories.</div>
            </div>
          </div>
        </header>
          {/*
        <div className="oak-intro center">
          Coming Soon
        </div>*/}
      </div>
    )
  }
}

export default CenturiesComingSoon;
