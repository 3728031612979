/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React from 'react';
import Meta from '../../Meta';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

const SEO = (props) => {
    let content = _.find( Meta, { url: props.url });
    if(!content) {
        content = _.find( Meta, { url: '/' })
    }

    return (
        <Helmet>
            <title>{ content.title }</title>
        </Helmet>
    );
}

export default SEO;