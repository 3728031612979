import React, { Component } from 'react';

// import tennesseeOak from '../images/tennesseeoak.png';
// import miniStory from '../images/ministory.png';
import treeWatermark from './treewatermark.svg';
import './state.css';

class StateComingSoon extends Component {
  render() {
    const countryBG = {
      backgroundImage: "url('" + treeWatermark + "')",
//      backgroundSize: '40%'
    }
    return (
      <div className="state states">
        <header className="center">
          <div className="pure-g state-wrapper">
            <div className="bg pure-u-1 pure-u-lg-1-2 center" style={ countryBG }>
              <span className="mt-35">States</span>
              <span className="coming-soon">Coming Soon</span>
            </div>
            <div className="pure-u-1 pure-u-lg-1-2 center-left">
              <div className="text">State to state, oaks grow strong in unique climates, temperatures and soil. Those characteristics are passed on to the bourbon.</div>
            </div>
          </div>
        </header>
        {/*
        <div className="oak-intro center">
        //  Coming Soon
        //  <div className="oak">
        //  </div>
        </div>*/}
      </div>
    )
  }
}

export default StateComingSoon;
