import React from 'react'
import  { Link } from 'react-router-dom';

const CanadianOak = (props) => {
  const direction = props.direction === 'right' ? 'ltr' : 'rtl';
  return (
    <div className="story" style={{direction: direction}}>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-lg-1-2 center">
          <img src={props.photo} alt="story" className="pure-img" />
          {/* <img src="https://via.placeholder.com/558x346" alt="story" className="pure-img" /> */}
        </div>
        <div className="pure-u-1 pure-u-lg-1-2">
          <div className="heading">{props.heading}</div>
          <p className="detail">How time, climate, and the Earth’s largest forest have shaped a species…and what that means for our whiskey </p>
          <button className="read-more no-decoration"><Link to="/story/canadian-oak">Read More</Link></button>
        </div>
      </div>
    </div>
  )
}

export default CanadianOak;