import React, { Component } from 'react';
import  { Link } from 'react-router-dom';
import {Fade, Slide } from 'react-reveal';

import Footer from '../../Footer';
import ShareLinks from '../ShareLinks';
import Bottle from '../Bottle';
import canadianOak from '../../../images/canadian_oak.png';

import story1 from './images/Canadian_Oak_Img_1.jpg';
import story2 from './images/Canadian_Oak_Img_2.jpg';
import story3 from './images/Canadian_Oak_Img_3.jpg';
import story4 from './images/Canadian_Oak_Img_4.jpg';

import './story.css';
import SEO from '../../SEO/SEO';

class CanadianOak extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
}
render() {
return (
  <div className="story-detail-canadian story-detail">
    <SEO url="story/canadian-oak" />
    <div className="intro" id="canadian-oak-intro">
      <div className="gradient"></div>
      <div className="intro-content">
        <Fade>
          <div className="pure-g">
            <div className="pure-u-1 pure-u-md-3-5">
              <div className="heading">Canadian Oak Bourbon</div>
              <div className="subheading">How time, climate, and the Earth’s largest forest have shaped a species…and what that means for our whiskey.</div>
            </div>
            <div className="pure-u-1 pure-u-md-2-5">
              <Bottle link="/bourbon/#mongolian-oak" bottle={canadianOak}/>
            </div>
          </div>
        </Fade>

        <div className="center">
          <ShareLinks />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="main center mg-tb-10">
        <Fade>
          <p className="mg-text-tb-40">
            You can’t rush bourbon. <br/><br/>
            Every stage of the process takes time—and it starts long before the white dog hits the barrel. Decades before the grain is planted, before the still is built, before the mash bill is perfected, an acorn falls deep in a forest, and an unseen clock starts ticking. Eventually, that acorn sprouts. It survives fires, floods, droughts, cold. It spends decades upon decades growing, strengthening itself and the forest it calls home. 

          </p>
        </Fade>
      </div>

      <div className="large">
        <Fade>
          <img src={story1} alt="" />
        </Fade>
      </div>

      <div className="container">
        <div className="main center normal-weight">
          <Fade>
            <p>
            That forest, like all forests, is the product of centuries of stewardship—by nature and by naturalists, shaped by weather and water and industry and economics. Nowhere is a better example of this than the largest intact forest on the planet: Canada’s boreal forest. It’s a place unlike any other on Earth, stretching from sea to sea, from the southern reaches of the Arctic Circle to the northern shores of the Great Lakes. 
              </p>
              <p>
              Along the southeastern border of this megaforest grows Quercus alba, the white oak. It’s a different beast than the American oaks that share the name. Even among the same species—the same forest, even—each tree is unique. And Canada’s massive forests and unique climate have shaped Quercus alba into a tree all its own.
                </p>
              <p>
              To compare: the American white oaks harvested and used in whiskey barrels across the world tend to come from forests that stretch from the Great Lakes to Tennessee, and from the Mississippi to the Atlantic. It’s an area with long growing seasons and hot, humid summers. In those conditions, the oaks grow fast, leading to a wider-grained wood that, when made into barrels, readily shares its flavors with the whiskey that ages within. 
            </p>
          </Fade>
        </div>
        <div className="large">
          <Slide up>
            <img src={story2} alt="" />
          </Slide>
        </div>
        <div className="container">
          <div className="main center normal-weight">
            <Slide up>
              <p>
              Canadian oak is a study in how geography alone can shape a species. Oaks look immortal, immovable, unchanging, but that’s not the case. Move a few hundred miles north and everything changes. The land is covered in trees, and forest becomes the rule, rather than the exception. Even in the peak of summer, the humidity drops. The winters are longer and harsher, the growing seasons shorter. Up here, life adapts, or it doesn’t survive. 
                </p>
              <p>
              The Canadian white oak has adapted. Those long, cold winters, cooler summers and abbreviated growing seasons have forced the tree to slow down. The fast growth rate southern white oaks are known for is absent here. In its place are trees that grow slower and stronger, taking their time to reach the crown of the forest. Their wood is tighter-grained, the pores smaller and finer. It’s a wood that releases flavors over longer periods, not all at once—a wood that shows that good things take time. Incidentally, these qualities also make it similar to the French Oak that’s often used to age some fo the world’s best wines. 
                </p>
            </Slide>
          </div>
        </div>
        <div className="large">
          <Fade>
            <img src={story3} alt="" />
          </Fade>
        </div>
        <div className="container">
          <div className="main center normal-weight">
            <Fade>
              <p>
              That’s the wood we use for the Canadian Oak expression of our Old Charter Oak bourbon. Carefully chosen and sustainably harvested from Canada’s vast forests, handcrafted into tight barrels and carefully filled with raw, unaged spirits, this is bourbon that really shows the power of time and the rewards that come with patience. 
                </p>
              <p>
              Though the barrels are new, the wood they’re made of is already the better part of a century old, shaped by the qualities and climate of the Canadian forest. Slowly, over months and years, that wood opens up to the bourbon, sharing the character that developed over decades of those long, cold winters and mild summers, surrounded by some of the most extensive forests on the planet.
                </p>
              <p className="min-height-0">
              You can taste it, that character. It might come across as caramel, berries, vanilla, syrup. It’s chocolatey, peppery, oaky. But what you’re really tasting, when you taste this bourbon—the spirit the product of years in the barrel, the barrel the product of years in the forest—is time. 
              </p>
            </Fade>
          </div>
        </div>

        <div className="container">
          <div className="main center normal-weight">
            <Fade>
              <p className="min-height-0">
              And after all those years, that time tastes pretty incredible. 
              </p>
            <div className="pure-u-1 pure-u-md-1-2">
              <div className="img center">
                <img className="pure-img" src={story4} alt="" />
              </div>
          </div>
          </Fade>
          </div>
          
        </div>
      </div>
      <ShareLinks />
    </div>
    <div className="nextstory">
      <div className="wrapper">
        <div className="heading">Buffalo Trace</div>
        <div className="subheading">The Distillery</div>
        <Link to="/distillery">
          <button
            className="read-more"
            onClick={() => document.documentElement.scrollTop = 0}
          >Read More</button>
        </Link>
      </div>
    </div>
    <Footer />
  </div>
)
}
}

export default CanadianOak;
