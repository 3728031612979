import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import mongolianOak from './mongolianoak.png';
import mongolianOak from '../images/17765_CharterOak_MongolianOak_750mL-small.png';
// import frenchOak from './frenchoakbottle.png';
import frenchOak from '../images/french_oak_bottle_v2.png';
import chinkapin from '../images/chinkapin_2x5.png';
import canadianOak from '../images/canadian_oak.png';

import miniCanadianStory from '../images/Canadian_Oak_Teaser.png';
import miniStory from '../images/mongolian-oak-teaser.png';
import miniFrenchStory from '../images/french-thumbnail.jpg';
import miniChinkapinStory from '../images/chinkapin-tree-thumbnail.jpg';
import treeWatermark from '../images/treewatermark.svg';
import './country.css';

class Country extends Component {
  componentDidMount() {
    const $anchor = window.location.hash;
    if($anchor) {
      const $el = document.getElementById($anchor);
      if($el) {
        $el.scrollIntoView();
      } 
    }
  }
  render() {
    const countryBG = {
      backgroundImage: "url('" + treeWatermark + "')",
      //backgroundSize: '40%'
    }
    return (
      <div className="country countries">
        <header className="center">
          <div className="pure-g">
            <div className="bg pure-u-1 pure-u-lg-1-2 center" style={ countryBG } >Countries</div>
            <div className="pure-u-1 pure-u-lg-1-2 center-left">
              <div className="text">From Mongolia to France, we carefully select white oaks grown in faraway places around the globe to age this bourbon.</div>
            </div>
          </div>
        </header>
          {/*Chinkapin Start*/}
          <div id="#chinkapin"></div>
          <div className="oak-intro center">Chinkapin Oak<div className="oak">
              <img src={chinkapin} alt="chinkapin" />
          </div></div>
          <div className="oak-info">
              <div className="pure-g">
                  <div className="pure-u-lg-9-24 pure-u-1">
                      <div className="name">Chinkapin Oak</div>
                      <p>This bourbon earned much of its character while aging for nine years in special barrels made from Chinkapin Oak trees. Prior to being assembled into barrels, the oak staves from these tall trees air-dried for two years, far longer than the industry-standard three months. As the bourbon matured at Buffalo Trace Distillery, the flavors from the oak became more complex, developed by the climate and changing seasons. The result is a bourbon uniquely shaped by the barrel, with notes of cherries and spearmint, a palate of fresh herbs and honey, with a finish of warm baking spices and dark chocolate.</p>
                      <div className="pure-g">
                          <div className="pure-u-5-5">
                              <div className="tasting-notes">Tasting Notes
                                <p>Chinkapin Oak delivers a nose of cherries and spearmint, with hints of floral notes. The palate features notes of fresh herbs and honey, followed by oak. It finishes with warm baking spices followed by dark chocolate. </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="pure-u-lg-6-24"></div>
                  <div className="pure-u-lg-9-24 pure-u-1 padding-top-25">
                      <div className="mini-story">
                          <img src={miniChinkapinStory} alt="story" />
                          <div className="story-title">
                              Chinkapin Oak Bourbon
                              <div className="detail">How wood, sun, rain, air, fire and time can make a bourbon extraordinary.</div>
                              <p className="link"><Link to="/story/chinkapin-oak" onClick={() => document.documentElement.scrollTop = 0}>Read</Link></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/*Chinkapin End*/}
          {/*French Oak Start*/}
          <div id="#french-oak"></div>
          <div className="oak-intro center">French Oak<div className="oak">
              <img src={frenchOak} alt="french oak" />
          </div></div>
          <div className="oak-info">
              <div className="pure-g">
                  <div className="pure-u-lg-9-24 pure-u-1">
                      <div className="name">French Oak</div>
                      <p>Aged twelve years at Buffalo Trace Distillery, this bourbon  is rich and sweet, drawing its character from barrels made  of French Oak. An oak that has long been used for aging the  world’s finest wine, the tighter grain of French Oak impart  more subtle flavors and a silkier texture than its American  counterpart. Harvested from the forests of France, the oak  barrels that aged this unique bourbon whiskey are honored  with each sip. So enjoy this tribute to the French Oak and raise  a glass to its storied journey from the forest, to the barrel, to bourbon.</p>
                      <div className="pure-g">
                          <div className="pure-u-5-5">
                              <div className="tasting-notes">Tasting Notes
                                  <p>French Oak delivers a nose of vanilla with hints of apricot, cherry, and rose petal. The palate features thick and creamy maple syrup, with the oak flavor coming through. The long finish features chocolate and toasted oak. </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="pure-u-lg-6-24"></div>
                  <div className="pure-u-lg-9-24 pure-u-1 padding-top-25">
                      <div className="mini-story">
                          <img src={miniFrenchStory} alt="story" />
                          <div className="story-title">
                              French Oak Bourbon
                              <div className="detail">The Unlikely Shared History of French Wine and American Bourbon</div>
                              <p className="link"><Link to="/story/french">Read</Link></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/*French Oak End*/}

          {/*Mongolian Oak Start*/}
          <div id="#mongolian-oak"></div>
        <div className="oak-intro center">Mongolian Oak<div className="oak">
          <img src={mongolianOak} alt="mongolian oak" />
        </div></div>

        <div className="oak-info">
          <div className="pure-g">
            <div className="pure-u-lg-9-24 pure-u-1">
              <div className="name">Mongolian Oak</div>
              <p>This bourbon is aged for ten years in oak barrels made from trees harvested in East Asia. An oak that takes root where many other trees cannot, <i>Quercus mongolica</i> is heralded for its ability to strengthen the earth beneath it, survive drought, and thrive in full sun. This bourbon honors the fortitude of those proud oaks. From the forests of Mongolia, these mighty oaks became the barrels in which this whiskey matured.
              </p>
              <div className="pure-g">
                <div className="pure-u-5-5">
                  <div className="tasting-notes">Tasting Notes
                    <p>
                    Mongolian Oak delivers a nose of pecan, caramel and pine.The palate features notes of cedar, clove, butterscotch and dark chocolate, with a finish of black pepper and smoked oak.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pure-u-lg-6-24"></div>
            <div className="pure-u-lg-9-24 pure-u-1 padding-top-25">
              <div className="mini-story">
                <img src={miniStory} alt="story" />
                <div className="story-title">
                  The story of Mongolian Oak
                  <div className="detail">Explore Mongolia through the eyes of Travel Channel's Jack Maxwell</div>
                  <p className="link"><Link to="/story/mongolia">Read</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
          {/*Mongolian Oak End*/}

          {/*Canadian Oak Start*/}
          <div id="#canadian-oak"></div>
          <div className="oak-intro center">Canadian Oak<div className="oak">
          <img src={canadianOak} alt="mongolian oak" />
        </div></div>

        <div className="oak-info">
          <div className="pure-g">
            <div className="pure-u-lg-9-24 pure-u-1">
              <div className="name">Canadian Oak</div>
              <p>For ten years, this bourbon aged and matured in Canadian Oak barrels at Buffalo Trace Distillery. The long, cold winters and mild summers of Canada’s northern climate make Canadian Oak uniquely suited to crafting exceptional bourbon. This distinctive environment leads to a tighter-grained wood that releases more of its signature oak aromatics while allowing the spirit to be aged longer in the barrel. The finished spirit features a nose of caramel and berries, a palate of vanilla and maple syrup, and a long finish with hints of oak, chocolate and pepper. Raise a glass to the storied forests of our northern neighbors and enjoy this spirit’s journey from acorn to oak to barrel to bourbon.
              </p>
              <div className="pure-g">
                <div className="pure-u-5-5">
                  <div className="tasting-notes">Tasting Notes
                    <p>
                    Canadian oak delivers a nose of caramel and berries, with a palate of vanilla and maple syrup. The long finish features oak, chocolate, and pepper.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pure-u-lg-6-24"></div>
            <div className="pure-u-lg-9-24 pure-u-1 padding-top-25">
              <div className="mini-story">
                <img src={miniCanadianStory} alt="story" />
                {/* <img src="https://via.placeholder.com/147x100" alt="story" /> */}
                <div className="story-title">
                  The story of Canadian Oak Bourbon
                  <div className="detail">How time, climate, and the Earth’s largest forest have shaped a species…and what that means for our whiskey.</div>
                  <p className="link"><Link to="/story/canadian-oak" onClick={() => document.documentElement.scrollTop = 0} >Read</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Canadian Oak End*/}
      </div>
    )
  }
}

export default Country;
