import React, { Component } from 'react';
import styled from 'styled-components';
import {Waypoint} from 'react-waypoint';

import { ScrollableLink } from '../../common/HashLink.jsx';

import SVG from 'react-inlinesvg';
import wholeStamp from './stampsvg.svg';
import underline from './underline.png';
//import wholeStamp from './backup.svg';
import './stamps.css';

const StickyNav = styled.div`
  @media only screen and (min-width: 1026px) {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: 0px;
    padding-left: 10.67vw;
    padding-right: 16.67vw;
    background: rgb(75, 30, 4, 0.4);
    background: hsla(22, 90%, 15%, 0.4);

    .stamp .isvg, .stamp .barrel-aged, .stamp .line {
        display: none;
    }

    .countries, .states, .species, .centuries{

        -moz-transition: all .12s ease-in;
        -o-transition: all .12s ease-in;
        -webkit-transition: all .12s ease-in;
        transition: all .12s ease-in;

    }

    .countries:hover {
        background: #6a2c5c !important;
    }

    .states:hover {
        background: #85040a !important;
    }

    .species:hover {
        background: #114104 !important;
    }

    .centuries:hover {
        background: #572b00 !important;
    }

    .types {
        padding: 0;
        position: sticky;
        position: -webkit-sticky;
        width: 100%;
        z-index: 1;
        top: 0;
    }
    .types a {
        text-decoration: none;
    }

    .stamp {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
    }

    .stamp-title {
        padding: 0;
        font-size: 20px;
        color: #f2e5be;
    }
 }

 @media not all and (min-resolution:.001dpcm) { @media {
    background: rgba(0, 0, 0, 0.2) !important;
}}

`;


class Stamps extends Component {
  
  constructor(props) {
    super(props);
    this.state = {stickHeader: true};
  }
  _handleLeave(positionObj) {
      const {currentPosition, previousPosition} = positionObj;
      if (previousPosition === currentPosition) {
          return false;
      } else if (currentPosition === 'above') {
          this.setState({stickHeader: true});
      }
  }
    _handleEnter(positionObj) {
        const {currentPosition, previousPosition} = positionObj;
        if (currentPosition === previousPosition || this.state.stickHeader === false) {
            return false;
        } else if (currentPosition === 'inside' && positionObj.event) {
            this.setState({stickHeader: true});
        }
    }
  stamps() {
    return (
      <div className="types sticky sticky-header">
        <div className="stamp countries">
          <ScrollableLink to='/bourbon/#mongolian-oak'>
            <div className="stamp-title">
                <span className="stamp-heading">Countries</span>
                <span className="hide-mobile sub-nav-sub">Oaks From Around The World</span>
                <img className="display-mobile" src={underline} alt=""/>
            </div>
          </ScrollableLink>
        </div>
        
        <div className="stamp states">
          <ScrollableLink to='/bourbon/#states'>
            <div className="stamp-title">
                <span className="stamp-heading">States</span>
                <span className="hide-mobile sub-nav-sub">Oaks From State To State</span>
                <img className="display-mobile" src={underline} alt=""/>
            </div>
          </ScrollableLink>
        </div>

          <div className="stamp species">
            <ScrollableLink to='/bourbon/#species'>
            <div className="stamp-title">
                <span className="stamp-heading">Species</span>
                <span className="hide-mobile sub-nav-sub">Various Species of Oak</span>
                <img className="display-mobile" src={underline} alt=""/>
            </div>
            </ScrollableLink>
          </div>

        <div className="stamp centuries">
          <ScrollableLink to='/bourbon/#centuries'>
            <div className="stamp-title">
              <span className="stamp-heading">Centuries</span>
                <span className="hide-mobile sub-nav-sub">Oaks Centuries Old</span>
                <img className="display-mobile" src={underline} alt=""/>
            </div>
          </ScrollableLink>
        </div>
      </div>
    );
  }
  stickyBar() {
    return (
        <StickyNav>
          {this.stamps()}
        </StickyNav>
    );
  }
  render(){
      const handleEnter = position => this._handleEnter(position);
      const handleLeave = position => this._handleLeave(position);
      const bar = this.state.stickHeader ? this.stickyBar() : this.stamps();
      return (
          <Waypoint
            onEnter={handleEnter}
            onLeave={handleLeave}>
            {bar}
          </Waypoint>
      );

  }
}

export default Stamps;
