import React, { Component } from 'react';
import  { Link } from 'react-router-dom';

class French extends Component {
    render () {
        const { props } = this;
        const direction = props.direction === 'right' ? 'ltr' : 'rtl';
        return (
            <div className="story" style={{direction: direction}}>
              <div className="pure-g">
                <div className="pure-u-1 pure-u-lg-1-2 center">
                  <img src={props.photo} alt="story" className="pure-img" />
                </div>
                <div className="pure-u-1 pure-u-lg-1-2">
                  <div className="heading">{props.heading}</div>
                  <p className="detail">The unlikely shared history of French wine and American bourbon.</p>
                  <Link to="/story/french"><button className="read-more no-decoration">Read More</button></Link>
                </div>
              </div>
            </div>
        );
    }
}

export default French;
