import React, { Component } from 'react';
import  { Link } from 'react-router-dom';


import Footer from '../../Footer';
import ShareLinks from '../ShareLinks';
import Bottle from '../Bottle';

import story1 from './images/story1.png';
import story2 from './images/story2.png';
import story3 from './images/story3.png';
import story4 from './images/story4.png';
import './story-tenn.css';

class Tennessee extends Component {
  render() {
    return (
      <div className="story-detail">
        <div className="intro">
          <div className="gradient"></div>
          <div className="intro-content">
            <div className="pure-g">
              <div className="pure-u-1 pure-u-lg-3-5">
                <div className="heading">Tennessee</div>
                <div className="subheading">Southern Heat</div>
              </div>
              <div className="pure-u-1 pure-u-lg-2-5">
                <Bottle />
              </div>
            </div>

            <div className="center">
              <ShareLinks />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="main center">
            <p>
            No living thing is more connected to, more a part of, more rooted to the planet than a tree.
            It’s our perfect opposite in many ways. Taking in our CO2 and breathing out vital oxygen.
            Generally speaking, it lives its entire life in one little plot of dirt.
            We humans on the other hand love to migrate and wander. Bering Strait, anyone?
            </p>
          </div>

          <div className="large">
            <img src={story1} alt="" />
          </div>

          <div className="container">
            <div className="text center">
              <p>
                The amount of moving we’ve done since the beginning of man boggles my mind. Especially limited to those early modes of transportation: feet and pack animals. Yet, move we did. For we are not trees. Which brings me to Mongolia and the Nomads, who traverse the most sparsely-populated nation on earth outside of Greenland…Mongolia! The name evokes images of marauding hordes on horseback led by the greatest of conquerors, Genghis Khan. It was all so Mongolian of him to vanquish not only what was in front of him, but also the far reaches of a world which was so much larger then.
                My trip to Mongolia began with an overhead-sized bag of questions that quickly grew to a steamer trunk full of curiosity. What’s Mongolia like? What does it mean to be Mongolian? You could get an answer to the first one traipsing around the capital, Ulaanbaatar, or the Gobi Desert. Not sure you could ever answer the second one in a lifetime of living amongst the locals.
                We were invited to spend the good part of a week with a nomad family. The hard part would be finding said family. No GPS out here. Even if there was, there are no street signs or man-made markers. (“Proceed to the route. Make a U-turn at the sand.”) Instead, we had to track hoof-prints out in the wild, follow those to a coven of camels out for their daily walk, and then wait till they decided it was time to go home. I say “coven” because they were nasty and evil, just like the storybook witches of my childhood. Wouldn’t be surprised if they gobbled up an occasional wayward kid following a trail of breadcrumbs. Whenever you got too close, they would begin braying or bleating out (or whatever camels do) the most daemonic sounds, while spitting like an infant learning it has lips. It doesn’t help they are lumpy and bumpy and lousy to ride. In spite of it all, the spiteful lead camel—like the ferryman on the river Styx—finally delivered us to our destination. I would call him Charon for the rest of the trip.
              </p>
            </div>
          </div>

          <div className="info-detail">
            <div className="pure-g">
              <div className="pure-u-1 pure-u-md-1-2">
                <div className="img center">
                  <img className="pure-img" src={story2} alt="placeholder" />
                </div>
              </div>
              <div className="pure-u-1 pure-u-md-1-2 center">
                <p className="text">
                  Nomads will pick up their family along with everything they own and just move, seemingly on a moment’s notice. Of course, I give them more credit than that. They’ve been thriving this way for thousands of years. Who am I to question? “But the kids really like their friends in this neighborhood” doesn’t fly here. After spending a few days on their land, I was comfortable enough to ask the patriarch of this 17-person family what it’s like to live without a living room and a roof over his head. Gently, he put his hand on my shoulder and said (translated from Mongolian): “Dear friend, the land is our living room and the sky is our roof.” That slayed me.
                  Upon arrival, but before we were allowed to unpack our gear we had to earn our stay. One of us (read: me) was required to finish off three bowls of fermented mare’s milk, known as Airag. Mostly for ceremony now, it goes back to the travelers of yore who might wish to hurt a family and steal their possessions. If you’ve had three bowls of fermented mare’s milk, trust me, you’re not in the mood for any of that nonsense. After holding up my end of the bargain, we were allowed to stay. My crew was going to owe me for this one.
                </p>
              </div>
            </div>

            <div className="container">
              <div className="text center">
                <p>
                  After a late night of Mongolian campfire songs and camel milk vodka, I awoke to the horrific screaming symphony with which our camel frenemies greet the morning. Unfortunately, this is before daybreak. Guess they’re in charge of waking up the roosters. So, although it was a tad early for my taste, I began my day, hangover and all. Strolling past the 73 camels our host owns, I mentioned something under my breath about “drinking your cousin last night” to Charon, who was making the loudest racket. Numbers say that wasn’t true, but it was the only heckle I could think of on short sleep and no coffee.
                  As my hike took me over a hill, I finally found myself awake and alert, surrounded by beauty up in the mountains. The air was clean and crisp. The scenery, serene. Something about those trees though. Then I wondered how sad it must be, whether planted by man or nature, to be stuck in one place and unable to roam free as the nomads do. How un-Mongolian. I stopped at one that looked like it could afford to give up a souvenir, peeled off a bit of bark and tasted it. One of the benefits of being the host of Booze Traveler is getting to try many things I thought would never pass my lips. Tree bark is one. With all the wonderful experimentation going on in relation to infusion, maceration and distillation in the world of alcohol, I feel it is my duty to taste things once considered abnormal. The tree might disagree. That bark was full of flavor. Hmmm. What is that? My trunkful (no pun intended) of questions was full to the brim.
                  Making my way back to base camp, I sought out the patriarch. I found him chopping wood while fending off with his back foot goats who were trying to eat it. The wood, not his foot. “What tree did I taste up there?” After asking why I was eating a tree, he told me I might have encountered the mighty Mongolian Oak. He said that particular one is greatly revered and carries the Mongolian name proudly. When I hear “oak,” I think whiskey barrels and delicious bourbon, but what do I know? I was more curious than before.
                </p>
              </div>
            </div>

            <div className="pure-g">
              <div className="pure-u-1 pure-u-md-1-2 center">
                <p className="text">
                  As soon as we got back to a place with Wi-Fi, and with my proverbial steamer trunk of questions now overflowing, I searched for answers. The Mongolian Oak, while retaining its exotic moniker, has apparently found its way to China, Korea, Siberia and, of all places, North Dakota. In fact it’s the largest tree in one of our most sparsely-populated (like Mongolia!) states. Oh, the things my new friend must have seen on his journey around the world. I’d love to sit down and have a drink with a Mongolian Oak someday. Because I now know what that tree knows. The world is a big, wide, wonderful place. It offers many flavors, in all the ways possible. You just have to open yourself up to the possibilities.
                  It is a nomad. It has seen the world. It is truly Mongolian after all. Genghis Khan would be proud.
                </p>
              </div>
              <div className="pure-u-1 pure-u-md-1-2">
                <div className="img center">
                  <img className="pure-img" src={story3} alt="placeholder" />
                </div>
              </div>
            </div>

            <div className="large">
              <img src={story4} alt="" />
            </div>
          </div>
          <ShareLinks />
        </div>
        <div className="nextstory">
          <div className="wrapper">
            <div className="heading">Buffalo Trace</div>
            <div className="subheading">The Distillery</div>
            <Link to="/distillery">
              <button
                className="read-more"
                onClick={() => document.documentElement.scrollTop = 0}
              >Read More</button>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Tennessee;