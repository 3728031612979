import React, { Component } from 'react';
import  { Link } from 'react-router-dom';
import {Fade, Slide } from 'react-reveal';

import Footer from '../../Footer';
import ShareLinks from '../ShareLinks';
import Bottle from '../Bottle';
import SEO from '../../SEO/SEO';
import chinkapinOak from '../../../images/chinkapin_2x5.png';

import story1 from './images/story1.jpg';
import story2 from './images/story2.jpg';
import story3 from './images/story3.png';
import story4 from './images/story4.jpg';
import './story-chin.css';

class Chinquapin extends Component {
  render() {
    return (
      <div className="story-detail-chinkapin story-detail">
    <SEO url="story/chinkapin-oak" />
    <div className="intro" id="chinkapin-oak-intro">
      <div className="gradient"></div>
      <div className="intro-content">
        <Fade>
          <div className="pure-g">
            <div className="pure-u-1 pure-u-md-3-5">
              <div className="heading">Chinkapin Oak Bourbon</div>
              <div className="subheading">How wood, sun, rain, air, fire and time can make a bourbon extraordinary.</div>
            </div>
            <div className="pure-u-1 pure-u-md-2-5">
              <Bottle link="/bourbon/#chinkapin-oak" bottle={chinkapinOak}/>
            </div>
          </div>
        </Fade>

        <div className="center">
          <ShareLinks />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="main center mg-tb-10">
        <Fade>
          <p className="mg-text-tb-40">
          It starts with the oak tree. But it doesn’t end there. <br/><br/>
          The oak trees selected to mature this bourbon were Chinkapin Oaks—Quercus muehlenbergii.  A species native to North America, like bourbon.  Chinkapin Oaks are often found in areas rich in natural limestone deposits and outcrops. Water filtered through that same limestone is part of what makes Kentucky uniquely suited to producing world-class bourbon.
          </p>
        </Fade>
      </div>

      <div className="large">
        <Fade>
          <img src={story1} alt="" />
        </Fade>
      </div>

      <div className="container">
        <div className="main center normal-weight">
          <Fade>
            <p className="min-height-0">
            After the majestic oaks are harvested, the wood must dry before it can be made into bourbon barrels. Most often, that drying time lasts a few months. But sometimes, the wood is exposed to the elements for years—and the payoff is worth the price.
              </p>
              <p className="min-height-0">
              During the two years this oak seasoned, the wood began to break down. The staves were exposed to the sun, wind, and rain. The flavors locked inside were released. The tannins softened. The character deepened. The oak staves evolved into something beyond what mere months of seasoning can create.
                </p>
              <p className="min-height-0">
              But time alone is not enough to unlock the flavor inside the wood. Another element is needed - fire. All bourbon whiskey is required to age in charred oak barrels, but not all barrels are charred alike. These barrels experienced less burn than others— the #3 char was just enough to caramelize the wood sugars and unlock the flavor in the oak.
            </p>
          </Fade>
        </div>
        <div className="large">
          <Slide up>
            <img src={story2} alt="" />
          </Slide>
        </div>
        <div className="container">
          <div className="main center normal-weight">
            <Fade>
              <p className="min-height-0">
              The decades of growth. The months of seasoning. The years spent aging in the barrel. This whiskey extracted the complex flavors buried deep within. The elements of wood, sun, rain, air, fire and time. The patience, care, and craftsmanship. Inside the bottle, it finally all comes together.
              </p>
              <p >
              The result is a whiskey uniquely shaped by the Chinkapin barrels and the environment. With a nose of cherries and spearmint with hints of floral notes, a palate of fresh herbs, honey and oak, and a finish of warm baking spices and dark chocolate, this bourbon is unlike anything else you’ve ever experienced—and worth the wait required to create it.
              </p>
            <div className="pure-u-1 pure-u-md-1-2">
              <div className="img center">
                <img className="pure-img" src={story4} alt="" />
              </div>
          </div>
          </Fade>
          </div>
          
        </div>
      </div>
      <ShareLinks />
    </div>
    <div className="nextstory">
      <div className="wrapper">
        <div className="heading">Buffalo Trace</div>
        <div className="subheading">The Distillery</div>
        <Link to="/distillery">
          <button
            className="read-more"
            onClick={() => document.documentElement.scrollTop = 0}
          >Read More</button>
        </Link>
      </div>
    </div>
    <Footer />
  </div>
    )
  }
}

export default Chinquapin;