import React from 'react'

const BgVideo = (props) => {
  return (
    <div className="video-bg">
      <video
        src={props.isVideoActive ? props.video : undefined}
        loop="loop"
        autoPlay="autoplay" 
        playsInline="playsinline"
      />
    </div>
  )
}

export default BgVideo;