/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

 A module to collect our animated react components.

  Components can be imported from here and easily changed
  site-wide.

  */

import styled, { keyframes } from 'styled-components';
import {
  pulse, fadeIn, slideInUp, slideInLeft, slideInRight
} from 'react-animations';

const animations = {fadeIn, slideInUp, slideInLeft, slideInRight, pulse}; // Add animations here

const animationKeyframe = animation => keyframes`${animation}`;

export function animatedElement(tag, animation) {
  /* Returns an element animated with react-animations.

      `tag`: e,g, "p" or "div"
      `animation`: name of the animation from react-animations
    */
  const reactAnimation = animations[animation];
  const animationKF = animationKeyframe(reactAnimation);
  return styled[tag]`
    animation: 2s ${animationKF}
  `;
}

const FadeInDiv = animatedElement("div", "fadeIn");

export {FadeInDiv};
