import React, { Component } from 'react';
import  { Link } from 'react-router-dom';
import {Fade, Slide } from 'react-reveal';

import Footer from '../../Footer';
import ShareLinks from '../ShareLinks';
import Bottle from '../Bottle';

import story1 from './images/french-story-1.jpg';
import story2 from './images/french-story-4.jpg';
import story3 from './images/french-story-2.jpg';
import story4 from './images/french-story-3.jpg';
import story5 from './images/french-story-bottle.jpg';
import './story.css';
import SEO from '../../SEO/SEO';

class French extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
  render() {
    return (
      <div className="story-detail-french story-detail">
        <SEO url="story/french" />
        <div className="intro">
          <div className="gradient"></div>
          <div className="intro-content">
            <Fade>
              <div className="pure-g">
                <div className="pure-u-1 pure-u-md-3-5">
                  <div className="heading">FRENCH OAK BOURBON</div>
                  <div className="subheading">THE UNLIKELY SHARED HISTORY OF FRENCH WINE AND AMERICAN BOURBON</div>
                </div>
                <div className="pure-u-1 pure-u-md-2-5">
                  <Bottle link="/bourbon/#mongolian-oak" />
                </div>
              </div>
            </Fade>

            <div className="center">
              <ShareLinks />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="main center mg-tb-10">
            <Fade>
              <p className="mg-text-tb-40">"If a tree falls in the forest, does it make a sound?" <br/><br/> You've heard this question before. But have you ever really stopped to think about what it means? It's a classic riddle revealing that reality is limited to our observation of it. Philosophers argue that when that tree falls, it only makes a sound if someone is around to hear it.</p>
            </Fade>
          </div>

          <div className="large">
            <Fade>
              <img src={story1} alt="" />
            </Fade>
          </div>

          <div className="container">
            <div className="main center normal-weight">
              <Fade>
                <p className="min-height-0">History is very much the same. Does a place have history if no one was around to observe, record and share it? </p>
                <p className="min-height-0">Take, for example, the Tronçais Forest in the heart of France. Over three centuries ago, this oak forest was planted to provide shipbuilding materials for the French Navy. Go back another couple millennia and that very same land was being cultivated by the ancient Romans.</p>
                <p className="min-height-0">The Tronçais Forest has a history and every tree that grows there is a relic. As such, everything created from those oak trees is part of that history.</p>
              </Fade>
            </div>
          </div>

          <div className="info-detail">
            <div className="pure-g">
              <div className="pure-u-1 pure-u-md-1-2">
                <Slide up>
                  <div className="img center main">
                    <img className="pure-img" src={story2} alt="placeholder" />
                  </div>
                </Slide>
              </div>
              <div className="pure-u-1 pure-u-md-1-2 main center normal-weight">
                <Slide up>
                  <p>Most coopers will tell you that what matters most when selecting oaks for barrels, is the grain. Did the tree grow slow and steady, resulting in a tighter grain? Or were conditions just right for rapid growth and therefore a wider grain pattern?</p>
                  <p>Common practice at cooperages is to classify the wood by grain. But some go as far as labeling cuts by provenance. Those coopers believe that where the tree grows has an influence on the characteristics of the barrel, and ultimately the flavor of the spirit it ages.</p>
                </Slide>
              </div>
            </div>

            <div className="container">
              <div className="main center normal-weight">
                <Fade>
                  <p className="min-height-0">Every place is different. Every forest has a unique set of variables—soil composition, rainfall, sunlight, seasonal fluctuations. So how does a barrel from the Tronçais Forest differ from a barrel from the Limousin Forest?</p>
                  <p className="min-height-0">Better yet, how does a barrel made of French Oak differ from a barrel made of American Oak?</p>
                  <p className="min-height-0">Maybe it's about more than just species or environment or climate. Maybe there's more to the story. Have you ever dined in France or Italy or Spain? It's an entirely unique experience because there's an epicurean tradition there that goes back thousands of years, and it's all connected to the land. In those places, farmers have been cultivating, growing and harvesting crops since the Neolithic Age.</p>
                </Fade>
              </div>
            </div>

            <div className="large">
                <Fade>
                    <img src={story3} alt="" />
                </Fade>
            </div>

            <div className="container">
                <div className="main center normal-weight">
                    <Fade>
                        <p>The history connected to that land is unimaginable. It's vast, interconnected and most importantly, meticulously documented. Take a bite of soupe à l'oignon, or a sip of French wine and let your imagination take you back in time. You're sharing in the same tradition as a Roman Senator or a French nobleman hundreds, or even thousands, of years ago.</p>
                    </Fade>
                </div>
            </div>

            <div className="large">
                <Fade>
                    <img src={story4} alt="" />
                </Fade>
            </div>

            <div className="container">
                <div className="main center normal-weight">
                    <Fade>
                        <p className="min-height-0">Sure, history must be observed to become history. But it demands to be shared in order to live on through our culture and traditions.</p>
                        <p className="min-height-0">If a tree is harvested from a forest in France, what kind of stories does it contain? How are those stories passed on to a spirit that ages within its grain?</p>
                        <p className="min-height-0">How can bourbon, a uniquely American product, be a relic of history that is so much older than America itself?</p>
                        <p className="min-height-0">Now that's a riddle worth savoring.</p>
                    </Fade>
                </div>
            </div>

            <div className="img center">
              <Fade>
                <img src={story5} alt="" />
              </Fade>
            </div>
          </div>
          <ShareLinks />
        </div>
        <div className="nextstory">
          <div className="wrapper">
            <div className="heading">Buffalo Trace</div>
            <div className="subheading">The Distillery</div>
            <Link to="/distillery">
              <button
                className="read-more"
                onClick={() => document.documentElement.scrollTop = 0}
              >Read More</button>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default French;
