/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React from 'react';
import { FadeInDiv } from '../ReactAnimations';
import LazyBackgroundDiv from '../common/LazyBackgroundDiv.jsx';
// import homeHeader from '../../images/home_header_tiny.jpg';

import WatchVideo from '../WatchVideo';

const Home = () => {
  return (
    // <div className="slide homepage">
      <LazyBackgroundDiv
        fullimg='home_header.jpg'
        tinyimg='home_header_tiny.jpg'
        className="slide homepage"
      >
        <div className="teaser">
            <FadeInDiv>
                <h1 className="heading">A Celebration of Oak</h1>
                <p className="details">Old Charter Oak Bourbon explores how the origin, age, species and preparation of white oaks influence the making of great American whiskey.</p>
            </FadeInDiv>
            <WatchVideo />
        </div>
      </LazyBackgroundDiv>
    // </div> 
  );
};

export default Home;
