import React, { Component } from 'react'

import BgVideo from '../BgVideo';
import WatchVideo from '../../WatchVideo';
import video from '../../../m/forest.mp4';

class TheForest extends Component {
  render() {
    return (
      <div className="slide forest">
        <BgVideo isVideoActive={this.props.isVideoActive} video={video} />
        <div className="teaser">
          <h1 className="heading">The Forest</h1>
          <h2 className="subheading">Weathering the Elements</h2>
          <p className="details">
            Each barrel starts as an oak tree in a forest. Enduring the heat of summer and winters that cut to the core, these oaks must stand the test of time before beginning their journey to becoming a barrel.
          </p>
          <WatchVideo />
        </div>
      </div>
    )
  }
}

export default TheForest;