/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import Headroom from 'react-headroom';

import charterOakLogo from '../../images/charteroak.svg';
import facebookLogo from '../../images/facebook.svg';
// import twitterLogo from '../../images/twitter.svg';
import instagramLogo from '../../images/instagram.svg';

import Stamps from './Stamps';

import './navbar.css';

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => {return { isMenuOpen: !prevState.isMenuOpen };});
  }

  render() {
    const {isMenuOpen} = this.state;
	  return (
      <Headroom disable={this.props.location.pathname === '/' ? true : false } >
        <div className={`navbar ${isMenuOpen ? ' menuopen' : ''}`}>
          <div className="brand">
            <Link to="/">
              <img src={charterOakLogo} alt="charter oak logo" />
            </Link>
          </div>
          <div className="nav">
            <NavLink exact to="/">Oak</NavLink>
            <NavLink exact to="/bourbon">Bourbon</NavLink>
            <NavLink exact to="/stories">Stories</NavLink>
            <NavLink exact to="/distillery">Distillery</NavLink>
          </div>
          <div className="social center upper-social">
            <a href="https://www.facebook.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
              <span className="logo center"><SVG src={facebookLogo} /></span>
            </a>
            <a href="https://www.instagram.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
              <span className="logo center"><SVG src={instagramLogo} /></span>
            </a>
            {/*
            <a href="https://www.twitter.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
              <span className="logo center"><SVG src={twitterLogo} /></span>
            </a>
            */}
          </div>

          <button className="menu-icon" onClick={this.toggleMenu}>
            <span className="menulines"></span>
          </button>

          <div className="navigation-box">
            <nav className="mainmenu">
              <ul>
                <li><Link to="/" onClick={this.toggleMenu}>Oak</Link></li>
                <li><Link to="/bourbon" onClick={this.toggleMenu}>Bourbon</Link></li>
                <li><Link to="/stories" onClick={this.toggleMenu}>Stories</Link></li>
                <li><Link to="/distillery" onClick={this.toggleMenu}>Distillery</Link></li>
              </ul>
              <div className="social">
                <a href="https://www.facebook.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
                  <span className="logo center"><SVG src={facebookLogo} /></span>
                </a>
                <a href="https://www.instagram.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
                  <span className="logo center"><SVG src={instagramLogo} /></span>
                </a>
                {/*
                <a href="https://www.twitter.com/oldcharteroak" target="_blank" rel="noopener noreferrer">
                  <span className="logo center"><SVG src={twitterLogo} /></span>
                </a>
                 */}
              </div>
            </nav>
          </div>
        </div>

        {this.props.location.pathname.indexOf('/bourbon') >=0 ? <Stamps /> : ''}
      </Headroom>
    );
  }
}

export default withRouter(Navbar);
