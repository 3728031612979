import React, { Component } from 'react'

import BgVideo from '../BgVideo';
import WatchVideo from '../../WatchVideo';
import video from '../../../m/cooperage.mp4';

class TheCooperage extends Component {
  render() {
    return (
      <div className="slide cooperpage">
        <BgVideo isVideoActive={this.props.isVideoActive} video={video} />
        <div className="teaser">
          <h1 className="heading">The Cooperage</h1>
          <h2 className="subheading">Becoming a Barrel</h2>
          <p className="details">
            It all comes together at the cooperage. Rough staves are seasoned by the elements for months—sometimes years—adding even more character to the oak. From there, a vessel for whiskey is created and the inside is charred to exact specifications.
          </p>
          <WatchVideo />
        </div>
      </div>
    )
  }
}

export default TheCooperage;