/** 
WWC Utility Library Functions 1.3
*  internalgit/react/lazylibs

Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 

import React from 'react';
import { HashLink } from 'react-router-hash-link';

export function ScrollableLink(props) {
  /* Fixes HashLink's offset. */
  const { to, children } = props;
  const options = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  };
  return (
    <HashLink
      to={to}
      scroll={el => el.scrollIntoView(options)}
    >
      {children}
    </HashLink>
  );
}
