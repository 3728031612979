/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Mongolia from './Mongolia';
import French from './French';
// import Tennessee from './Tennessee';
import Chinquapin from './Chinquapin';
import CanadianOak from './CanadianOak';
import Buffalo from './Buffalo';
// import WatchVideo from '../WatchVideo';
import './story.css';
import mongolia from '../../images/mongolia.png';
import french from '../../images/french-stories-preview.jpg';
// import tennessee from '../../images/tennessee.png';
import chinkapin from '../../images/chinkapin-tree.jpg';
import canadianOak from '../../images/Canadian_Oak_Header.jpg';
import buffalo from '../../images/distillery_header.jpg';
import SEO from '../SEO/SEO';

class Stories extends Component {
  render() {
    return (
      <div className="stories">
        <SEO url="stories" />
        <div className="intro">
          <div className="title">
            <div>
              <p className="heading">Charter Oak Tree</p>
              <p className="subheading">The Legend</p>
            </div>
              <Link to="/distillery/history"> <button className="read-more read-more-stories orange">Read More</button></Link>
          </div>
        </div>
        <div className="stories-list">
          <Chinquapin heading="Chinkapin Oak Bourbon" photo={chinkapin} direction="right"/>
          <CanadianOak heading="Canadian Oak Bourbon" photo={canadianOak} />
          <French heading="FRENCH OAK BOURBON" photo={french} direction="right" />
          <Buffalo heading="Buffalo Trace, The Distillery" photo={buffalo}/>
          <Mongolia heading="Mongolia, The Journey" photo={mongolia} direction="right" />
            {/* <Tennessee heading="Tennessee, Southern Heat" photo={tennessee} direction="right" /> */}
          
          
        </div>
        <Footer />
      </div>
    )
  }
}

export default Stories;
