/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 

'use strict';

module.exports = [
    {
        url: '/',
        title: 'Kentucky Straight Bourbon Whiskey | Old Charter Oak',
        description: 'Old Charter Oak Kentucky Straight Bourbon Whiskey from the Buffalo Trace Distillery is a celebration of oak barrels in making the best bourbon whiskey.',
        keywords: ''
    },
    {
        url: 'bourbon',
        title: 'Bourbon | Old Charter Oak | Buffalo Trace Distillery',
        description: 'Every bottle of Old Charter Oak is a testament to the oak tree that gave the spirit its character. The best bourbon from Buffalo Trace Distillery is aged in oak.',
        keywords: ''
    },
    {
        url: 'stories',
        title: 'Stories | Old Charter Oak | Buffalo Trace Distillery',
        description: 'The stories behind Old Charter Oak Bourbon tell the tale of how oak and oak barrels carry the story of bourbon from start to finish. ',
        keywords: ''
    },
    {
        url: 'distillery',
        title: 'Buffalo Trace Distillery | Old Charter Oak',
        description: 'Only the best bourbon whiskey is aged at Buffalo Trace Distillery.  Each expression is an exploration in the variations of oak barrels in great American Whiskey.',
        keywords: ''
    },
    {
        url: 'story/mongolia',
        title: 'Mongolian Oak Bourbon | The Journey | Old Charter Oak',
        description: 'Jack Maxwell from the Travel Channel travels to Mongolia to Mongolia inspired by Old Charter Oak Mongolian Oak Bourbon from Buffalo Trace Distillery',
        keywords: ''
    },
    {
        url: 'distillery/history',
        title: 'The Legend of the Charter Oak Tree | Old Charter Oak',
        description: 'In 1874, The Old Charter whiskey brand was crafted. This bourbon legend of the old charter oak tree began when a mighty oak tree became the subject of history.',
        keywords: ''
    },
    {
        url: 'story/french',
        title: 'French Oak Bourbon | Old Charter Oak',
        description: 'French Oak Bourbon. The unlikely shared history of French Wine and American Bourbon Whiskey by Buffalo Trace Distillery in Kentucky.',
        keywords: ''
    },
    {
        url: 'story/canadian-oak',
        title: 'Canadian Oak Bourbon | Old Charter Oak',
        description: 'The bourbon that inspired the journey is Canadian Oak Bourbon from Old Charter Oak aged in Kentucky at the Buffalo Trace Distillery.',
        keywords: ''
    },
    {
        url: 'story/chinkapin-oak',
        title: 'Chinkapin Oak Bourbon | Old Charter Oak',
        description: 'How wood, sun, rain, air, fire and time can make a bourbon extraordinary.',
        keywords: ''
    }
]