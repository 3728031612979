/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';

import Home from './Home';
import TheBourbon from './TheBourbon';
import TheCooperage from './TheCooperage';
import TheForest from './TheForest';
import TheStavemill from './TheStavemill';
import Scroll from './Scroll';
import Footer from '../Footer';
import './charteroak.css';
import SEO from '../SEO/SEO';

function detectmob() { 
  if( navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)
  ){
     return true;
   } else {
     return false;
   }
 }

class CharterOak extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      activeSlide: ''
    };
  }

  updateVideo = (origin, destination, direction) => {
    // mobile doesn't ever get video 
    if(!detectmob()) {
      this.setState({
        activeSlide: destination.anchor
      });
    } else
      // this is only needed for when crazy people toggle their browser between desktop and mobile user agents
      this.setState({
        activeSlide: ''
    });
  }

  render() {
    const fullpageOptions = {
      anchors: ['oak', 'theforest', 'thestavemill', 'thecooperpage', 'thebourbon'],
      sectionsColor: ['#f2f2f2', '#4BBFC3', '#f0e8dd', 'whitesmoke', '#000', 'red'],
      parallaxKey: process.env.REACT_APP_FULLPAGE_PARALLAX_LICENSE_KEY,
      licenseKey: 'A05D6008-C6624FC1-A2B234C3-7C08B014',
      afterLoad: this.updateVideo,
      navigation: true,
    };

    return (
      <div>
        <SEO url='/' />
        <div className="overlay">
          {this.props.location.hash !== '#footer' && <Scroll />}
        </div>

        <ReactFullpage
          {...fullpageOptions}
          render={({ state, fullpageApi }) => {
            return (
              <div>
                <div className="section">
                  <Home />
                </div>
                <div className="section">
                  <TheForest isVideoActive={this.state.activeSlide === "theforest" ? true : false} />
                </div>
                <div className="section">
                  <TheStavemill isVideoActive={this.state.activeSlide === "thestavemill" ? true : false} />
                </div>
                <div className="section">
                  <TheCooperage isVideoActive={this.state.activeSlide === "thecooperpage" ? true : false} />
                </div>
                <div className="section">
                  <TheBourbon isVideoActive={this.state.activeSlide === "thebourbon" ? true : false} />
                </div>
                <div className="section fp-auto-height">
                    <Footer />
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(CharterOak);
