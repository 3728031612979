import React, { Component } from 'react';

// import speciesOak from '../images/tennesseeoak.png';
// import miniStory from '../images/ministory.png';
import treeWatermark from './speciestree.svg';
import './species.css';

class SpeciesComingSoon extends Component {
  render() {
    const countryBG = {
      backgroundImage: "url('" + treeWatermark + "')",
//      backgroundSize: '40%'
    }
    return (
      <div className="species">
        <header className="center">
          <div className="pure-g">
            <div className="bg pure-u-1 pure-u-lg-1-2 center" style={ countryBG }>
                <span className="mt-35">Species</span>
                <span className="coming-soon">Coming Soon</span>
            </div>
            <div className="pure-u-1 pure-u-lg-1-2 center-left">
              <div className="text">Many species exist within the white oak subgenus of <i>Quercus</i>. This bourbon explores the variations in flavor extracted from various species of white oak.</div>
            </div>
          </div>
        </header>
          {/*
          <div className="oak-intro center">
          Coming Soon
        </div>*/}
      </div>
    )
  }
}

export default SpeciesComingSoon;
