/** 
WWC Utility Library Functions 1.3
*  internalgit/react/lazylibs

Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 

import React, {Component} from 'react';

class LazyImage extends Component {
  /* Lazily loads background image, with a blur effect

    Props:
    `fullimg` and `tinyimg` are file paths relative
    to the `src/images/` directory
  */
  constructor(props) {
    super(props);
    const {tinyimg} = props;
    this.state = {hdImage: null, smImage: null};
    import(`../../images/${tinyimg}`).then(
      img => {
        console.log('Got the image!');
        this.setState({smImage: img.default});
      }
    );
  }

  componentDidMount() {
    const {fullimg} = this.props;
    import(`../../images/${fullimg}`).then(
      img => this.setState({hdImage: img.default})
    );
  }

  render() {
    const styles = {
      backgroundImage: this.state.hdImage || this.state.smImage,
      transition: 'filter 1s',
      filter: !this.state.hdImage ? 'blur(2px)' : 'none'
    };
    return(
      <img {...this.props}
        style={styles}
        src={this.state.hdImage || this.state.smImage} >
      </img>
    );
  }
}
export default LazyImage;
