import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Fade, Slide } from 'react-reveal';

import ShareLinks from '../ShareLinks';
import Footer from '../../Footer';

import history1 from './images/HistoryImg1.jpg';
import history2 from './images/HistoryImg2.jpg';
import history3 from './images/HistoryImg3.jpg';
import oldCharterOak from './images/Old-Charter-Oak.png';
import './history.css';
import SEO from '../../SEO/SEO';

class History extends Component {
  render() {
    return (
      <div className="distillery history">
        <SEO url="distillery/history" />
        <div className="intro no-story">
          <Fade>
            <div className="intro-content">
              <div className="heading">
                The Legend of the Charter Oak Tree
              </div>
              <div className="center">
                <ShareLinks />
              </div>
            </div>
          </Fade>
        </div>
        <div className="container">
          <div className="main center mg-tb-10">
            <Fade>
              <p className="center mg-text-tb-40">
                In 1874, Adam and Ben Chapeze crafted the Old Charter whiskey brand and named it after the famous Charter Oak tree. But this story began centuries before, when a mighty oak tree became the subject of legend.
              </p>
            </Fade>
          </div>

          <div className="large">
            <Fade>
              <img src={history1} alt="history" />
            </Fade>
          </div>

          <div className="info-detail">
            <div className="pure-g">
              <div className="pure-u-1 pure-u-md-1-2 main center normal-weight">
                <Slide left>
                  <p>
                    As the story goes, a band of English soldiers under order of King James II arrived in Hartford, Connecticut in 1687. Their mission was to seize the Royal Charter of Connecticut, thereby nullifying the colony’s right to govern itself. In a meeting cloaked in secrecy, the charter disappeared before it could be confiscated by the English Governor General.
                  </p>
                  <div className="img center main">
                    <img className="pure-img" src={history3} alt="placeholder" />
                  </div>
                </Slide>
              </div>
              <div className="pure-u-1 pure-u-md-1-2 main center">
                <div className="img center">
                  <Slide right>
                    <img className="pure-img" src={history2} alt="hiding the original charter in oak" />
                  </Slide>
                </div>
              </div>
            </div>
            <div className="main center normal-weight">
              <Fade>
                <p className="center">
                  According to legend, the charter was smuggled away under the veil of sudden darkness, as every candle in the meetinghouse was at once extinguished. Hidden in the hollow of a sprawling oak at a nearby estate, the charter remained in the colony’s possession and that centuries-old tree became immortalized as a symbol of American independence.
                </p>
              </Fade>
            </div>
          </div>
          <div className="large main center">
            <Fade>
              <img src={oldCharterOak} alt="old charter oak" />
            </Fade>
          </div>
          <div className="main center normal-weight">
            <Fade>
              <p className="center">
                Today, we’re honoring the story of the Charter Oak tree by making bourbon in its name. This is a bourbon that explores the influence of different kinds of oaks on the making of America’s native spirit. Each bottle of Old Charter Oak is a vessel for stories—for those the bourbon tells about the oak, and those we tell over a glass of bourbon.
              </p>
            </Fade>
          </div>
          <ShareLinks />
        </div>

        <div className="nextstory">
          <div className="wrapper">
            <div className="heading">Mongolia</div>
            <div className="subheading">The Journey</div>
            <Link to="/story/mongolia">
              <button
                className="read-more"
                onClick={() => document.documentElement.scrollTop = 0}
              >Read More</button>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default History;