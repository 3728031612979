import React from 'react'
import  { Link } from 'react-router-dom';

const Buffalo = (props) => {
  const direction = props.direction === 'right' ? 'ltr' : 'rtl';
  return (
    <div className="story buffalo" style={{direction: direction}}>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-lg-1-2 ">
          <img src={props.photo} alt="story" className="pure-img" />
        </div>
        <div className="pure-u-1 pure-u-lg-1-2">
          <div className="heading text-red">{props.heading}</div>
          <p className="detail set-ltr">Old Charter Oak is a celebration of the pivotal role oak barrels play in the making of great American whiskey. Each expression is an exploration in variations of oak barrels.</p>
          <Link to="/distillery"><button className="read-more no-decoration"
                                         onClick={() => document.documentElement.scrollTop = 0}
          >Read More</button></Link>
        </div>
      </div>
    </div>
  )
}

export default Buffalo;
