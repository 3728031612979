import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Fade, Slide } from 'react-reveal';

import ShareLinks from '../ShareLinks';
import Footer from '../../Footer';

// import { FadeInDiv } from '../../ReactAnimations';

import barrels from './images/barrels.png';
import artwork from './images/distillery_img1.jpg';
// import buffalTrace from './images/buffalo-trace.png';
import bTours from './images/distillery_img3.jpg';
import btdSpring from './images/distillery_img4.jpg';
import btdSpring2 from './images/distillery_img7.jpg';
import btdSpring3 from './images/distillery_img5.jpg';
import btBarrels from './images/distillery_img6.jpg';
import './distillery.css';
import SEO from '../../SEO/SEO';

class Distillery extends Component {
  render() {
    return (
      <div className="distillery">
        <SEO url="distillery" />
        <div className="intro no-story">
          <Fade>
            <div className="intro-content">
              <div className="heading">Born From Buffalo Trace Distillery’s Curiosity</div>
              <div className="center">
                <ShareLinks />
              </div>
            </div>
          </Fade>
        </div>
        <div className="container">
          <div className="main center mg-tb-10">
            <Fade>
              <p className="center mg-text-tb-40">For more than 200 years, Buffalo Trace Distillery has been distilling whiskey on the banks of the Kentucky River. It’s on this site that millions of barrels of bourbon with names like Taylor, Weller, Stagg and Van Winkle are aging in century-old warehouses, waiting patiently to tell their stories. </p>
            </Fade>
          </div>

          <div className="show">
            <Slide left>
              <img className="img center" src={artwork} alt="barrels" />
            </Slide>
            <Slide right>
              <img className="img center" src={barrels} alt="buffalo trace" />
            </Slide>
          </div>

          <div className="main center normal-weight">
            <Fade>
              <p className="center">
                Alongside these bourbon legends are countless barrels dedicated to exploring the craft of whiskey-making. It’s that curiosity that led to Old Charter Oak—a collection of bourbons celebrating the influence of various types of oak trees on the taste of whiskey.
              </p>
            </Fade>
          </div>

          <div className="large center">
            <Fade><img src={bTours} alt="buffalo trace tour" /></Fade>
          </div>

          <div className="info-detail">
            <div className="pure-g">
              <div className="pure-u-1 pure-u-md-1-2 main center normal-weight">
                <Slide left>
                  <p>As the world’s most award-winning distillery, Buffalo Trace has earned most than 500 accolades from the industry’s foremost writers, enthusiasts and spirits competitors. In 2013, we added our proudest distinction yet as we were recognized as a National Historic Landmark.</p>
                  <div className="img center main">
                    <img className="pure-img" src={btdSpring3} alt="placeholder" />
                  </div>
                  <div className="img center main">
                    <img className="pure-img" src={btdSpring2} alt="placeholder" />
                  </div>
                </Slide>
              </div>
              <div className="pure-u-1 pure-u-md-1-2 main center normal-weight">
                <Slide right>
                  <div className="img center main">
                    <img className="pure-img" src={btdSpring} alt="placeholder" />
                  </div>
                  <p>Buffalo Trace Distillery is named in honor of the ancient buffalo that carved trails through the wilderness, leading early American explorers westward. One such trail led to the banks of the Kentucky River, where we’ve been making whiskey the same way for centuries. </p>
                  <div className="img center main">
                    <img className="pure-img" src={btBarrels} alt="placeholder" />
                  </div>
                </Slide>
              </div>
            </div>
          </div>
          <ShareLinks />
        </div>
        <div className="nextstory">
          <div className="wrapper">
            <div className="heading">Charter Oak Tree</div>
            <div className="subheading">The Legend</div>

            <Link to="/distillery/history"><button
              className="read-more white"
              onClick={() => document.documentElement.scrollTop = 0}>
            Read More
          </button>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Distillery;
