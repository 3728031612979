/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React, { Component } from 'react';
import { ScrollableLink } from '../common/HashLink.jsx';

import { FadeInDiv } from '../ReactAnimations';
// import { animatedElement } from '../ReactAnimations';
import Footer from '../Footer';
import Country from './Country';
// import State from './State';
import StateComingSoon from './StateComingSoon';
// import Species from './Species';
import SpeciesComingSoon from './SpeciesComingSoon';
// import Centuries from './Centuries';
import CenturiesComingSoon from './CenturiesComingSoon';
// import Stamps from './Stamps';
import './bourbons.css';

import LazyBackgroundDiv from '../common/LazyBackgroundDiv.jsx';
import LazyImage from '../common/LazyImage.jsx';

import SEO from '../SEO/SEO';

class Bourbons extends Component {
  render() {
    return (

      <div className="bourbons">
          {/*<Stamps />*/}
        <SEO url="bourbon" />
        <LazyBackgroundDiv
          fullimg='bourbons-background.png'
          tinyimg='bourbons-background_tiny.png'
          className="intro">
          <div className="pure-g">
            <div className="pure-u-1-3">
              <div className="oak">
                <LazyImage className="desktop-only"
                  //fullimg='bottlesmall.png'
                  fullimg='bigbottle.png'
                  tinyimg='bigbottle.png'
                  alt="bourbon"
                />
                <LazyImage className="mobile-only"
                  //fullimg='bottlesmall.png'
                  fullimg='bottlesmall.png'
                  tinyimg='bottlesmall.png'
                  alt="bourbon"
                />
              </div>
            </div>
            <div className="pure-u-2-3">
              <FadeInDiv className="wrapper">
                <div className="heading">The Bourbon</div>
                <div className="subheading">Every Oak Tree Tells a Story</div>
                <div className="details">
                  <p>Old Charter Oak Bourbon is made to tell those stories. In honor of the pivotal role oak barrels play in the age-old tradition of bourbon-making, every bottle of Old Charter Oak is a testament to the unique oak tree that gave this spirit its character.</p>
                  <div className="explore">
                    <p className="explore-text">Explore the bourbons</p>
                    <ScrollableLink to="/bourbon/#mongolian-oak">
                      <div className="arrow"></div>
                    </ScrollableLink>
                  </div>
                </div>
              </FadeInDiv>
            </div>
          </div>
        </LazyBackgroundDiv>

        <div ref="mongolian-oak" id="mongolian-oak" className="scroll-offset-more" >
          <Country />
        </div>
        <div ref="state" id="states" className="scroll-offset" >
          <StateComingSoon />
        </div>
        <div ref="species" id="species" className="scroll-offset" >
        <SpeciesComingSoon />
        </div>
        <div ref="centuries" id="centuries" className="scroll-offset" >
          <CenturiesComingSoon />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Bourbons;
