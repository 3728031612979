import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import CharterOak from './CharterOak';
import Bourbons from './Bourbons';
import Stories from './Stories';
import Navbar from './Navbar';
import AgeGate from './AgeGate';
// import SEO from './SEO/SEO';

import {
  Distillery,
  History,
  Mongolia,
  CanadianOak,
  French,
  Tennessee,
  Chinquapin
} from './StoryPages';
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = {
      showAgeGate: true
    };
  }

  toggleAgeGate = () => {
    this.setState({
      showAgeGate: !this.state.showAgeGate
    });
  }

  render() {
    return (
      <div>
        {/* <SEO url="/" /> */}
        <Navbar />
        <Switch>
          <Route path="/bourbon" component={Bourbons} />
          <Route path="/stories" component={Stories} />
          <Route path="/distillery" exact component={Distillery} />
          <Route path="/distillery/history" component={History} />
          <Route path="/story/mongolia" component={Mongolia} />
          <Route path="/story/canadian-oak" component={CanadianOak} />
          <Route path="/story/french" component={French} />
          <Route path="/story/tennessee" component={Tennessee} />
          <Route path="/story/chinkapin-oak" component={Chinquapin} />
          <CharterOak />
        </Switch>

        {this.state.showAgeGate && <AgeGate closeAgeGate={this.toggleAgeGate} /> }
      </div>
    );
  }
}

export default App;
