import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import BgVideo from '../BgVideo';
import video from '../../../m/bourbon.mp4';

class TheBourbon extends Component {
  render() {
    return (
      <div className="slide thebourbon">
        <BgVideo isVideoActive={this.props.isVideoActive} video={video} />
        <div className="teaser">
          <h1 className="heading">The Bourbon</h1>
          <h2 className="subheading">A Vessel for Stories</h2>
          <p className="details">
            Without oak barrels, there would be no bourbon. Oak is a defining ingredient of bourbon whiskey, influencing nearly three-fourths of its flavor. When you drink Old Charter Oak, raise a glass to the storied journey from the forest, to the barrel, to the bourbon.
          </p>
          <div class="embiggen">
          <Link to="/bourbon">
            <button className="read-more homepage-readmore">Read more</button>
          </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default TheBourbon;