/** 
OCO Project 
React Code & Project Coypright 2018-2019 Wendell Wilson Consulting. All rights reserved. 

**/ 
import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import YouTube from 'react-youtube';

import './style.css';

const youtubeVideoId = '0gjYyiCqkeA';
const youtubeOptions = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 0
  }
};


class WatchVideo extends Component {
  state = {
    open: false,
  };
  
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div className="embiggen">
        <button className="watch-video" onClick={this.onOpenModal} >Watch Video</button>
        <Modal
          center
          open={open}
          onClose={this.onCloseModal}
          classNames={{ closeIcon: 'close', modal: 'modal embiggen'  }}
        >
          <YouTube
            videoId={youtubeVideoId}
            opts={youtubeOptions}
            onReady={this._onReady}
          />
        </Modal>
      </div>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default WatchVideo;
